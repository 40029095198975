<template>
  <login-page-layout v-if="!userStore.loaded || !userStore.loggedIn || !userStore.isEnabled" />
  <main-layout v-else>
    <avatar-image v-if="userStore.avatarUuid" :image-uuid="userStore.avatarUuid" class="btn-border" size="20px" />
  </main-layout>
</template>

<script lang="ts" setup>
import { MainLayout } from '@editor/ui-layout';
import { useUserStore } from '@editor/ui-utils';
import { AvatarImage } from '@modules/image-editor-ui';
import { useQuasar } from 'quasar';
import { onMounted, watch } from 'vue';

import LoginPageLayout from '@/components/login-page.layout.vue';
import { useOnlineCheck } from '@/composables/useOnlineCheck';

import usePing from './composables/usePing';
import featherIcons from './featherIcons';

const $q = useQuasar();
const customIcons = [
  'cat-tool',
  'kim',
  'gallery',
  'grid-customer-list',
  'javascript',
  'nested-navigation',
  'video-performance',
  'flag-de',
  'flag-en',
  'flag-it',
  'schedule-lock',
  'ai-generated-icon',
  'reschedule-save',
  'save-push',
  'publish-push',
  'cta',
];

// Add support for feather icons
$q.iconMapFn = (icon: string) => {
  if (customIcons.includes(icon)) {
    return {
      icon: `svguse:/custom.svg#${icon}`,
    };
  }
  const i = featherIcons[icon];
  if (i) {
    return {
      icon: i,
    };
  }
};

const userStore = useUserStore();

watch(
  () => userStore.loaded,
  (loaded) => {
    if (loaded) {
      $q.loading.hide();
    } else {
      $q.loading.show();
    }
  },
  { deep: true },
);
$q.loading.show();
userStore.loadCurrentUser();

onMounted(() => {
  usePing();
  useOnlineCheck();
});
</script>
<style lang="scss" src="./moduleLoader.scss" />
<style lang="scss">
@import '@quasar/extras/material-icons/material-icons.css';
@import '@quasar/extras/roboto-font/roboto-font.css';
@import 'quasar/src/css/index.sass';
@import '@editor/ui-utils';
@import '@editor/ui-layout';
@import './scss/main';
@import './scss/override';
@import './scss/variables';
</style>
